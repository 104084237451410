<template>
  <div class="footer-box">
    <div class="footer clearfix">
      <div class="footer-top clearfix">
        <div class="container">
          <ul>
            <li v-for="(item,index) in footerList"
                :key="index">
              <router-link :to="item.path">{{item.title}}</router-link>
              <ul>
                <li v-for="(j,i) in item.items"
                    :key="i"
                    @click="addFooter(j,)"><router-link :to="j.path">{{j.name}}</router-link></li>
              </ul>
            </li>
          </ul>
          <div class="footer-contact"
               style="padding-right: 425px;width: 49.5%;">
            <!-- <div class="tel"><span>全国咨询服务热线</span><p><a href="tel:0934-8622119" class="call">0934-8622119</a></p></div><div class="address"><span>公司地址</span><p>甘肃省庆阳市西峰区朔州西路天辰大厦19楼</p></div> -->
            <div class="tel"><span>{{$t('footerContent.qgzxfwrx')}}</span>
              <p><a :href="'tel:'+companyInfoList.title"
                   class="call">{{companyInfoList.title}}</a></p>
            </div>
            <div class="address"><span>{{$t('footerContent.gsdz')}}</span>
              <p style="line-height: 18px;font-size:14px;">{{$t('footerContent.gsdzvalue')}}</p>
            </div>
            <!--#include file="/dblxfs.shtml"-->
            <!-- 联系方式 -->
            <div class="link clearfix">
<!--              <div class="item"><span class="btn f2"></span>-->
<!--                <div class="hover"><img src="../../assets/img/ewm.png"-->
<!--                       alt="" />-->
<!--                  <p>{{$t('footerContent.gzwxgzh')}}</p>-->
<!--                  <div class="arrow"></div>-->
<!--                </div>-->
<!--              </div>-->
              <div class="item"></div>
<!--              <div class="item"><span class="btn f1"></span>-->
<!--                <div class="hover"><img src="../../assets/img/ewm.png"-->
<!--                       alt="" />-->
<!--                  <p>{{$t('footerContent.gzwb')}}</p>-->
<!--                  <div class="arrow"></div>-->
<!--                </div>-->
<!--              </div>-->
              <!--#include file="/dbgzfx.shtml"-->
              <!-- 分享按钮 -->
            </div>
            <div class="ewm"
                 style="margin-right: 5%;">
              <!--#include file="/dbappxz.shtml"-->
              <!-- app下载 -->
<!--              <div>-->
<!--                <img src="../../assets/img/qrcode_for_gh_9183a2f83e7b_1280(3).jpg"-->
<!--                     alt="">-->
<!--                <p>{{$t('footerContent.wxgzh')}}</p>-->
<!--              </div>-->
<!--              <div>-->
<!--                <img src="../../assets/img/327.jpg"-->
<!--                     alt="">-->
<!--                <p>{{$t('footerContent.APPxz')}}</p>-->
<!--              </div>-->
<!--              <div>-->
<!--                <img src="../../assets/about/jzh-app-ewm.jpg"-->
<!--                     alt="">-->
<!--                <p>{{$t('footerContent.jzhxz')}}</p>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom clearfix">
        <div class="container">
          {{$t('footerContent.bq')}}Copyright © 2022 All rights reserved
          <!-- 粤ICP备20005897号 -->
          <!-- 粤ICP备20005897号-3 -->
          <!-- <a target="_blank"
             href="https://beian.miit.gov.cn/">{{domain=='xn--drua831axt0b.xn--fiqs8s'?'粤ICP备20005897号-5':domain=='www.bbjt88.com'?'粤公网安备44030602007949号':'粤公网安备44030602007949号'}}</a> -->
             <br><a href="https://beian.miit.gov.cn" target="blank">粤ICP备2024271504号&nbsp;&nbsp;</a>
             <a href="http://www.beian.gov.cn/portal/registerSystemlnfo?recordcode=44030602007949" target="blank">粤公网安备44030602007949号</a>
            </div>
      </div>
    </div>
    <div class="footer-min">
      <el-collapse v-model="activeNames"
                   @change="handleChange">
        <el-collapse-item v-for="(item,index) in footerList"
                          :key="index"
                          :title="item.title"
                          :name="index+1">
          <div v-for="(i,j) in item.items"
               :key="j"
               @click="addFooter(i)">{{i.name}}</div>
        </el-collapse-item>
      </el-collapse>
      <div class="footer-bottom">
        <div class="app-img">
          <img src="../../assets/img/327.jpg"
               alt="">
          <p>{{$t('footerContent.xz')}}</p>
        </div>
        <div class="info">
          <!-- <div class="tel"><span>全国咨询服务热线</span><p><a href="tel:0934-8622119" class="call">0934-8622119</a></p></div><div class="address"><span>公司地址</span><p>甘肃省庆阳市西峰区朔州西路天辰大厦19楼</p></div> -->
          <div class="tel"><span>{{$t('footerContent.qgzxfwrx')}}</span>
            <p><router-link :to="'tel:'+companyInfoList.title"
                 class="call">{{companyInfoList.title}}</router-link></p>
          </div>
          <div class="address"><span>{{$t('footerContent.gsdz')}}</span>
            <p>{{$t('footerContent.gsdzvalue')}}</p>
          </div>
        </div>
      </div>
      <div class="footer-bottom clearfix">
        <div class="container">
          {{$t('footerContent.bq')}}Copyright © 2022 All rights reserved
          <!-- <a target="_blank"
             href="https://beian.miit.gov.cn/">{{domain=='xn--drua831axt0b.xn--fiqs8s'?'粤ICP备20005897号-5':domain=='www.bbjt88.com'?'粤公网安备44030602007949号':'粤公网安备44030602007949号'}}</a> -->
             <a href="https://beian.miit.gov.cn" target="blank">粤ICP备2024271504号&nbsp;&nbsp;</a>
             <a href="http://www.beian.gov.cn/portal/registerSystemlnfo?recordcode=44030602007949" target="blank">粤公网安备44030602007949号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BbjtwebsiteFooterContent",

  data () {
    return {
      domain: '',
      activeNames: ['1'],
      footerList: [
        {
          title: 'cpzxsy',
          path: '/product',
          items: [
            { name: 'znaqm', path: '/product/safetyHat', distance: 700 },
            { name: 'ywbjq', path: '/product/smokeDetector', distance: 700 },
            { name: 'dwsb', path: '/product/watch', distance: 700 },
            { name: 'zhyd', path: '/product/smartElectricity', distance: 700 },
            { name: 'znsb', path: '/product/waterMeter', distance: 700 },
            { name: 'znpt', path: '/product/platForm', distance: 700 },
            { name: 'yyfc', path: '/product/touringCar', distance: 700 },
            { name: 'xfcl', path: '/product/fireEngine', distance: 700 },
          ]
        },
        {
          title: 'jjfa',
          path: '/solution',
          items: [
            { name: 'zhcs', path: '/solutionDetail?id=48', distance: 4750 },
            { name: 'zhgc', path: '/solutionDetail?id=43', distance: 4750 },
            { name: 'zhxf', path: '/solutionDetail?id=45', distance: 4750 },
            { name: 'zhgd', path: '/solutionDetail?id=44', distance: 4750 },
            { name: 'zhsq', path: '/solutionDetail?id=46', distance: 4750 },
            { name: 'zhxy', path: '/solutionDetail?id=47', distance: 4750 },
          ]
        },
        {
          title: 'bbzx',
          path: '/dynamic',
          items: [
            { name: 'bbdt', path: '/dynamic?tab=0', distance: 4750 },
            { name: 'hydt', path: '/dynamic?tab=1', distance: 4750 },
            { name: 'bbbk', path: '/dynamic?tab=2', distance: 4750 },
          ]
        },
        {
          title: 'fwzc',
          path: '/serve',
          items: [
            { name: 'zlxz', path: `/serve?tab=0`, distance: 4750 },
            { name: 'rjxz', path: '/serve?tab=1', distance: 4750 },
            { name: 'gysly', path: '/serve?tab=2', distance: 4750 },
            { name: 'yhxy', path: '/serve?tab=3', distance: 4750 },
            { name: 'ystk', path: '/serve?tab=4', distance: 4750 },
            { name: 'fhtkzc', path:'/serve?tab=5', distance: 4750 },
          ]
        },
        {
          title: 'gybb',
          path: '/about',
          items: [
            { name: 'bbjj', path: '/about?tab=0', distance: 4750 },
            { name: 'bbwh', path: '/about?tab=1', distance: 4750 },
            { name: 'ryzz', path: '/about?tab=2', distance: 4750 },
            { name: 'lxwm', path: '/about?tab=3', distance: 4750 },
          ]
        },
      ],
      companyInfoList: {}
    };
  },
  mounted () {
    this.domain = window.document.domain
    this.footerList.forEach((item, index) => {
      // console.log('打印了表土', item.title)
      var sssd = '' + item.title
      item.title = this.$t('footerContent.' + item.title)
      for (var listitm of item.items) {
        if (sssd == 'cpzxsy') {
          listitm.name = this.$t('product.' + listitm.name)
        } else {
          listitm.name = this.$t('footerContent.' + listitm.name)
        }
      }
    })
    if (localStorage.getItem('companyInfo')) {

      this.companyInfoList = JSON.parse(localStorage.getItem("companyInfo"))
    }
  },
  // onLoad () {
  //   let domains = window.document.domain
  //   if ('www.bbjt.cc' == domains) {
  //   }
  // },
  methods: {
     async addFooter (i) {
      console.log(i)
      console.log(i.path);
      var path = i.path.substring(1,i.path.indexOf('?'))
      console.log(path)
      if (path == "solution") {
        this.$router.push({
          path: i.path ,
        });
      } else if (path == 'dynamic') {
        // localStorage.setItem("sharing", i.name);
     this.$router.push({
          path: i.path
        });
      } else if (path == 'serve') {
        // localStorage.setItem("sharing", i.name);
        await this.$router.push({
          path: i.path ,
        });
        location.reload();
      } else if (path == 'product') {
        this.$router.push({
          path: i.path
        });
      } else if (path == 'about') {
        this.$router.push({
          path: i.path
        })
      } else {
        // localStorage.setItem("sharing", i.name);
        this.$router.push({
          path:i.path
        });
        if (i.distance) {
          document.documentElement.scrollTop = i.distance;
        }
      }

      // this.$router.push({
      //   path: `${j.path}`,
      // });
      localStorage.setItem("sharing", i.name);
      document.documentElement.scrollTop = i.distance;

    },
    handleChange (val) {
      console.log(val);
    }
  },
};
</script>

<style lang="less" scoped>
.footer-contact {
  // padding-right: 425px;
}
.footer-bottom {
  a {
    color: #fff;
  }
}
.footer-min {
  display: none;
}

@media screen and (max-width: 750px) {
  .footer {
    display: none;
  }

  .footer-box {
    background: #1c1819;
    .tel {
      background: url("../../assets/images/online.png") no-repeat left 7px;
      span {
        color: #5e5b5c;
      }
      p {
        font-size: 18px;
      }
    }
  }
  .footer-min {
    display: block;
    .footer-bottom {
      margin-top: 20px;
      padding: 0 10px;
      display: flex;
      .app-img {
        img {
          width: 120px;
        }
      }
      .info {
        margin-left: 30px;
      }
    }
  }
}
</style>
<style>
.el-collapse-item__header {
  background: #1c1819 !important;
  color: aliceblue !important;
  font-size: 17px !important;
  padding-left: 10px;
  border-bottom: 1px solid #ebeef5;
}
.el-collapse-item__content {
  background: #1c1819 !important;
  color: #5e5b5c !important;
  font-size: 15px !important;
  padding-left: 10px;
}
.el-collapse-item__header.is-active {
  border-bottom: 1px solid #fff;
}
@media screen and (max-width: 750px) {
  .el-collapse-item__header {
    font-size: 12px !important;
    height: 41px !important;
  }
  .el-collapse-item__content {
    font-size: 12px !important;
    padding-bottom: 15px !important;
  }
  .footer-box .tel p {
    font-size: 15px !important;
  }
  .app-img {
    width: 33% !important;
  }
  .address {
    display: flex !important;
  }
  .address span {
    width: 95px !important;
  }
  .address p {
    text-align: left;
  }
}
</style>
