<template>
  <div class="index">
    <header class="header header1 clearfix" id="header" v-if="!isMobile">
      <top-nav />
    </header>
    <main id="main">
      <router-view />
    </main>
    <template>
      <footer-content v-if="!isMobile"></footer-content>
    </template>
  </div>
</template>

<script>
import FooterContent from '../components/common/footerContent.vue'
import TopNav from '../components/common/topNav.vue'
export default {
  props: [],
  data () {
    return {
      isWeiXin:false,
      isMobile:false
    }
  },
  name: "videoPLay",
  components: { TopNav, FooterContent },
  mounted () {
    this.setLang()
    this.checkIfMobile()
  },
  methods: {
    checkIfWeiXin(){
      let that = this
      var ua = navigator.userAgent.toLocaleLowerCase()
      if(ua.indexOf("micromessenger")!=-1){
        that.isWeiXin = true
      }else{
        that.isWeiXin = false
      }
    },
    checkIfMobile(){
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    },
    setLang () {
      var langStr = (navigator.language || navigator.browserLanguage).toLowerCase();
      // if (langStr.indexOf('zh') != -1) {
      //     console.log("当前语言环境为中文")
      //     localStorage.setItem('lang',"zh");
      // }else if (langStr.indexOf('en') != -1) {
      //     console.log("当前语言环境为英文")
      //     localStorage.setItem('lang',"en");
      // }else if (langStr.indexOf('es') != -1) {
      //     console.log("当前语言环境为西班牙文")
      //     localStorage.setItem('lang',"es");
      // }else {
      //     console.error("当前语言环境没有对应的翻译版本：" + langStr)
      // }
    }
  },
  watch: {
  }
}
</script>

<style lang="less" scoped>
#main {
  margin-top: 155px;
}

@media screen and (max-width: 750px) {
  #main {
    margin-top: 130px;
  }
}

.header {
  height: 45px;
}
</style>
